<template>
  <div>
    <el-container style="height: 100%">
      <div class="flex-col">
        <el-aside class="flex-width"><adminList></adminList> </el-aside>
        <el-container>
          <el-header>
            <span
              style="
                display: inline-block;
                float: left;
                color: #0085d0;
                font-size: 30px;
                margin-left: 25px;
              "
              >{{ title }}</span
            >
            <span class="adminName" @click="loginOutBtn">退出登录</span>
          </el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="(item, index) in breadList"
              :key="index"
              :to="{ path: item.path }"
              >{{ item.meta.title }}</el-breadcrumb-item
            >
          </el-breadcrumb>
          <el-main><router-view></router-view></el-main>
          <el-dialog
            title="退出"
            :visible.sync="loginOutDialog"
            width="45%"
            :before-close="handleClose"
          >
            <div>确定退出登录</div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" class="btnCO" @click="loginOut"
                >确定</el-button
              >
              <el-button class="btnCO" @click="loginOutDialog = false"
                >取消</el-button
              >
            </span>
          </el-dialog>
        </el-container>
      </div>
      <el-footer></el-footer>
    </el-container>
  </div>
</template>

<script>
import adminList from "./adminList.vue";
export default {
  name: "admins",
  data() {
    return {
      loginOutDialog: false,
      breadList: [],
      title: "",
    };
  },
  components: { adminList },
  destroyed() {},
  watch: {
    //路由改变的时候监听
    $route() {
      this.getBreadcrumbs();
    },
  },
  computed: {},
  mounted() {
    this.getBreadcrumbs();
    let aa = JSON.parse(localStorage.getItem("shanInfor"));
    this.title = aa.company;
  },
  methods: {
    //路由面包屑
    getBreadcrumbs() {
      console.log(this.$route.matched, "matched");
      this.breadList = this.$route.matched;
    },
    handleClose(done) {
      console.log(done);
    },
    //退出登录弹框
    loginOutBtn() {
      this.loginOutDialog = true;
    },
    //退出登录提交
    loginOut() {
      let aa = JSON.parse(localStorage.getItem("shanInfor"));
      let that = this;
      that
        .$http({
          url: "/foo/login/logOut",
          params: { userId: aa.id },
          method: "POST",
        })
        .then((res) => {
          console.log("退出：", res.data);
          if (res.data.code == 200) {
            this.loginOutDialog = false;
            that.$message({
              message: "成功退出登录",
              type: "success",
            });
            // this.$router.replace("/");
            this.$router.replace("/AlarmApiManage");
            window.localStorage.removeItem("shanInfor");
            window.localStorage.removeItem("tokenShan");
          } else {
            this.$message.error(res.data.msg);
            this.loginOutDialog = false;
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
.el-container {
  display: flex;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
}
.el-container.is-vertical {
  flex-direction: column;
}
.flex-width {
  width: auto !important;
}
.flex-col {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.el-header {
  background-color: #fff;
  color: #333;
  line-height: 60px;
  padding-right: 55px;
  margin-bottom: 15px;
}
#headImagen {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid #0085d0;
  margin-right: 18px;
  margin-left: 20px;
}
#headImagea {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  margin-right: 18px;
  margin-left: 20px;
  margin-bottom: 13%;
}
.adminName {
  font-size: 17px;
  color: #0085d0;
  font-weight: bold;
  cursor: pointer;
  float: right;
}
.menuName {
  font-size: 15px;
  line-height: 30px;
  color: #606266;
}
/deep/.el-dialog__title {
  color: #0085d0;
}
/deep/.el-dialog {
  border-radius: 20px;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: #0085d0;
}
.btnCO {
  background-color: #0085d0;
  color: #fff;
  height: 40px;
  width: 100px;
  border: none;
  border-radius: 33px;
  font-size: 17px;
}
.el-breadcrumb {
  padding: 0 25px !important;
  font-size: 18px;
  padding-bottom: 25px !important;
}
/deep/.el-breadcrumb__inner {
  color: #92aca7;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #92aca7;
}
// /deep/.el-menu-item.is-active {
//   background-color: #f1a795 !important;
//   color: #ab6a5b !important;
// }
/deep/ .el-menu-item.is-active {
  background-color: #0085ee !important;
  color: #fff !important;
}
/deep/.el-dialog__body {
  padding: 30px 20px;
  font-size: 15px;
}
.codeBtn {
  padding: 11px 20px;
  float: right;
}
</style>