<template>
  <div style="height: 100%">
    <el-menu
      background-color="#0085d0"
      text-color="#ffffff"
      active-text-color="#000"
      class="el-menu-vertical-demo"
      router
    >
      <div class="header">
        <!-- <div class="imgs">
        <img src="../../../assets/logo1.png" alt />
      </div> -->
        <!-- <div class="title"> -->
        <!-- <span>{{title}}</span> -->
        <!-- </div> -->
      </div>
      <el-menu-item index="/AlarmApiManage/menu/userManagement"
        ><i class="el-icon-s-custom"></i>用户管理</el-menu-item
      >
      <el-menu-item index="/AlarmApiManage/menu/deviceManagement"
        ><i class="el-icon-s-management"></i>设备管理</el-menu-item
      >
      <el-menu-item index="/AlarmApiManage/menu/Alarmhistory"
        ><i class="el-icon-location"></i>报警历史</el-menu-item
      >
      <el-menu-item index="/AlarmApiManage/menu/Keymanagement"
        ><i class="el-icon-document"></i>秘钥管理</el-menu-item
      >
      <el-menu-item index="/AlarmApiManage/menu/subscriptionAdmin"
        ><i class="el-icon-setting"></i>订阅管理</el-menu-item
      >
      <el-menu-item index="/AlarmApiManage/menu/sleepAdmin"
        ><i class="el-icon-moon-night"></i>睡眠管理</el-menu-item
      >
      <el-menu-item index="/AlarmApiManage/menu/log"
        ><i class="el-icon-document-copy"></i>API日志</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "adminList",
  data() {
    return {
      title: "",
    };
  },
  created() {
    let aa = JSON.parse(localStorage.getItem("shanInfor"));
    this.title = aa.company;
  },
  destroyed() {},
  methods: {},
};
</script>

<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
.header {
  background: #0085d0;
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin-bottom: 34px;
}
i {
  color: #fff;
  margin-right: 20px !important;
  font-size: 28px !important;
}
.el-menu-vertical-demo {
  height: 100%;
  padding: 0 0px;
  padding-top: 20px;
}
.el-menu-item {
  padding: 0 73px !important;
  font-size: 21px;
  text-align: center;
  height: 66px;
  line-height: 66px;
}
</style>